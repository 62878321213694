<template>
    <section>
        <div class="row mx-3 mt-3 mb-4">
            <i class="icon-left cr-pointer text-general f-18" @click="$emit('volver')" />
            <div class="col f-18 text-general f-600">
                Asignar Geocercas
            </div>
        </div>
        <div class="row mx-3 pl-5">
            <div class="col-12 pl-0">
                <small class="pl-3 text-general">
                    Seleccionar grupo de geocercas
                </small>
                <el-select v-model="idCarpetaGeocerca" class="w-100" size="small" @change="selectGeocercas">
                    <el-option
                    v-for="item in geocercaCarpeta"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-12 pl-0 my-3 mb-4">
                <small class="pl-3 text-general">
                    Seleccionar geocerca
                </small>
                <el-select v-model="idGeocerca" placeholder="Select" class="w-100" size="small" @change="geocercaLista">
                    <el-option
                    v-for="(item,i) in geocercas"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    :disabled="item.disabled"
                    />
                </el-select>
            </div>
            <div v-for="(g,key) in idsGeocercas" :key="key" class="col-12 d-middle pl-0">
                <i class="icon-geocerca f-18 text-general" />
                <p class="col px-2 f-16 text-general pl-2">
                    {{ g.nombre }}
                </p>
                <i class="icon-delete-trash text-general cr-pointer" @click="geocercaListaEliminar(g.id)" />
            </div>
            <div v-if="$can('gestionar_asignacion_geocercas')" class="col-12 mt-5">
                <div class="btn-general" @click="asignarGeocercaLeechero">
                    Asignar
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Geocerca from '~/services/geocerca/geocerca'
export default {
    props: {
        idGrupo:{
            type: Number,
            required: true,
        },
    },
    data(){
        return {
            geocercaCarpeta: [],
            geocercas: [],
            idCarpetaGeocerca: null,
            idGeocerca: null,
            idsGeocercas: [],
        }
    },
    mounted(){
        this.getGrupoGeocerca()
    },
    methods: {
        async getGrupoGeocerca(){
            try {
                const {data} = await Geocerca.selectCarpetaGeocerca()
                this.geocercaCarpeta = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async selectGeocercas(){
            try {
                const {data} = await Geocerca.selectGeocercas({idCarpeta: this.idCarpetaGeocerca, idGrupo: this.idGrupo})
                this.geocercas = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        geocercaLista(item){
            let that = this
            this.geocercas.forEach(function(value){
                if (value.id == item){
                    value.disabled = true
                    var datos = {
                        nombre: value.nombre,
                        id: item
                    }
                    that.idsGeocercas.push(datos)
                }
            })
            this.idGeocerca = null
        },
        geocercaListaEliminar(id){
            let that = this
            this.geocercas.forEach(function(value){
                if (value.id == id){
                    value.disabled = false
                    let idx = that.idsGeocercas.findIndex(p=>p.id === id)
                    that.idsGeocercas.splice(idx, 1);
                }
            })
        },
        async asignarGeocercaLeechero(){
            try {
                if(this.idsGeocercas.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos una (1) geocerca','warning')
                    return
                }

                const payload = {
                    idGrupo: this.idGrupo,
                    idGeocercas: _.map(this.idsGeocercas, 'id')
                }

                await Geocerca.asignarGeocercaLeechero(payload)

                this.notificacion('','Grupo geocerca asignado correctamente','success')
                this.$emit('volver');
                this.$emit("cargar");
            } catch (e){
                this.error_catch(e)
            }
        },
    },
}
</script>
